<template>
  <div>
    <b-row style="margin-top: 30px;">
      <b-col sm="12" lg="10"> 
        <h5>Ticket</h5>
      </b-col>
    </b-row>
    <div class="line"></div>
    <div v-for="(ticket, ticket_ind) in d_passiveTicket" v-if="f_searchTicket(ticket)" @mouseover="d_hoverOrganizationIndex = ticket_ind" @mouseleave="d_hoverOrganizationIndex = ''">
      <b-row style="margin-top: 3px;">
        <b-col sm="12" lg="1">
          <div :style="ticket_ind % 2 === 0 ? 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: left; box-shadow: -1px -1px 2px rgba(0, 0, 0, 1); background-color: rgba(28, 214, 163, 1); background-image: linear-gradient(45deg, rgba(28, 214, 163, 1) 0%, rgba(28, 214, 163, 1) 100%); min-width: 20px; margin: 5px; min-height: 20px;' : 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: left; box-shadow: rgb(0, 0, 0, 1) -1px -1px 2px; background-color: rgba(6 181 254, 1); background-image: linear-gradient(45deg, rgb(6 181 254 / 100%) 0%, rgb(6 181 254) 100%); min-width: 20px; margin: 5px; min-height: 20px;'">
          </div>
        </b-col>
        <b-col sm="12" lg="3">
            <p style="color: green;">{{ ticket.organization_label }}</p>
        </b-col>
        <b-col sm="12" lg="4">
          <div class="ticket_item" @click="f_goToThisTicket(ticket.organization_id, ticket.operation_id, ticket.status)" :style="StoreDevice.isMobile ? 'cursor: pointer; margin: 10px;' : 'cursor: pointer; margin: 1px;'">
            <span :style="d_hoverOrganizationIndex === ticket_ind ? 'font-weight: bold; color: rgb(252, 0, 153, 1);' : ''">{{ ticket.label }}</span>
          </div>
        </b-col>
        <b-col sm="12" lg="2">
            <p style="color: green;">{{ ticket.operation_cd }}</p>
        </b-col>
        <b-col sm="12" lg="2">
<!--           <template v-for="(task, task_ind) in d_taskList">
            <div v-if="task && task.data && task.data.general" style="padding: 3px;">
              <div class="task-comment" @click="f_showComments(task_ind, 'all_task_list')">
                <img src="@/icon/1519119.png" width="20px" style="border-radius: 3px; cursor: pointer;" /> {{ d_taskCommentSeenList[task.id] }}
              </div>
            </div>
          </template> -->
            <div v-if="ticket.operation_data && ticket.operation_data.data.general" style="padding: 3px;">
              <div class="task-comment">
                <img src="@/icon/1519119.png" width="20px" style="border-radius: 3px; cursor: pointer;" /> {{ d_taskCommentSeenList[ticket.operation_data.id] }}
              </div>
            </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { WmanagerApi } from '@/services/Wmanager/WmanagerApi';
import WdmService from '@/services/wdm';
import {
  default as Modal
} from "@/components/widgets/Modal";
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import OrganizationService from '@/services/organization';
import moment from 'moment';
export default {
  name: 'PassiveTicketList',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
  }),
  components: {
    vSelect,
    Modal
  },
  data () {
    return {
      d_loading: {
        'status': false,
      },
      d_hoverOrganizationIndex: '',
      d_organizationSelect: {
        "selected_organization": null,
        "selected_subject": "",
        "selected_operation_name": "",
        "selected_operation_about": "",
      },
      d_passiveTicket: [],
      d_selectedWdmr307: '',
      d_organizationSubjectList: '',
      d_userSearch: { 'text': '', 'search_list': [], 'search_count': 0 },
      d_organizationRecordList: [],
      d_searchText: '',
      d_WmanagerLayerData: require('@/services/Wmanager/Layers').Layers,
      d_selectedOrganizationIndex: '',
      d_organization: {
        'id': 'new',
        'name': '',
        'about': '',
        'list': [],
        'tree_detail': { 'label': 'Kullanıcı Bilgileri' },
        'tree_name': { 'label': 'Kullanıcı Listesi' },
        'type': 'organization'
      },
      d_taskList: [],
      d_taskCommentSeenList: {},
      d_selectedTaskForComment: {},
      selected_task_index: '',
      show_organization_modal: false,
      d_organizationList: [],
      d_organizationIdList: [],
      d_organizationWdm22List: [],
      user: {},
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    // console.log("user,", this.user)
    this.f_getOrganizationList();
  },
  beforeMount () {},
  mounted: function () {
    setTimeout(function () {
      let doc = document.getElementById('organization_search');
      doc.focus();
    }, 500);
  },
  methods: {

    f_selectWdmr307: function () {
      // console.log("....",this.d_organizationSelect.selected_organization.w_id)
      if (this.d_organizationSelect.selected_organization.w_id){
        let wdmr307_id = this.d_organizationSelect.selected_organization.w_id;
        let organization_id = wdmr307_id;
        // if (!this.$route.query === undefined || (this.$route.query !== undefined && (this.$route.query.organization_id === undefined || (this.$route.query.organization_id !== organization_id)))) {
        //   let route_data = {
        //     'query': {
        //       'organization_id': organization_id
        //     }
        //   };
        //   this.$router.push(route_data);
        // }
        let query = 'organization_id=' + organization_id;
        // this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Organizasyon verisi getirme işlemi gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        OrganizationService.organization_get(query)
          .then(resp => {
            if (resp.data.status_code === '12000') {
              // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
              this.d_selectedWdmr307 = resp.data.organization_data;
              if ("subject_list" in this.d_selectedWdmr307.data.general){
                this.d_organizationSubjectList = this.d_selectedWdmr307.data.general.subject_list.list
                // console.log("yyy:", this.d_organizationSubjectList );
              }
              else{
                this. d_organizationSubjectList = "";
              }
            } else {
              console.log('error :' + resp.data.message);
            }
          }, resp => {
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            console.log(resp);
            let msg = JSON.stringify(resp);
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Organizasyon getirme işlemi hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
          });
      }
    },
    f_getOrganizationList: function () {
      this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Organizasyonlar getirme işlemi gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      OrganizationService.organization_list()
        .then(resp => {
          this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '12000') {
            this.d_organizationList = resp.data.list;
            for(let i in this.d_organizationList){
              this.d_organizationIdList.push(this.d_organizationList[i].w_id)
            }
            // console.log("d_organizationIdList", this.d_organizationIdList)
            this.f_getWdm17OfThisOrganization();

          } else {
            alert('error ', resp.data.status_code + ' ' + resp.data.status_message);
          }
        });
    },

    f_searchTicket: function (ticket) {
      let res = false;
      if (this.d_searchText) {
        if (ticket.label.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
          res = true;
        }
      } else {
        res = true;
      }
      return res;
    },
    f_getWdm17OfThisOrganization: function () {
      // let wdm307_id = this.d_selectedWdmr307.id;
      // console.log('wdm307_id: ', wdm307_id);
      // console.log('w_id_list: ', w_id_list);
      /*
        d_pagination: {
          'start': 0,
          'end': 49,
          'current': 1,
          'perpage': 50
        },
      */
      let wdm307_id_list = this.d_organizationIdList
      // console.log("wdm307_id_list:", this.d_organizationIdList)
      if (!this.d_loading.status) {
        this.d_loading.status = true;
        let limit = '';
        let offset = '';
        // if (this.d_organizationListData.selected_index !== '') {
        //   limit = this.d_pagination.perpage.toString();
        //   offset = this.d_pagination.start.toString();
        // }
        let order = ''; // asc or desc
        // let last_case_point = this.d_wdmrListByAlgorithmResponse.last_case_point;
        let last_case_point = '';
        let start_type = 'end';
        let layer_x = 'layer' + this.d_WmanagerLayerData['operation'].layer_id;
        let sql_text = 'SELECT ' + layer_x + ' FROM ' + layer_x;
        let where_sql = '';
        where_sql = this.f_createSqlWhere();
        // if (wdm307_id_list.length > 0) {
        //   sql_text += '.start([' + wdm307_id_list.join(',') + '])';
        // }

        sql_text += where_sql
        let data = {
          'sql_text': sql_text,
          'last_case_point': last_case_point,
          'limit': limit,
          'offset': offset,
          'order': order,
          'organization_id_list': wdm307_id_list
        };
        OrganizationService.operation_get_by_wmanager_sql(data)
          .then(resp => {
            this.d_loading.status = false;
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            this.d_wdmrListByAlgorithmResponse = {
              // 'end_log_operation_id': resp.data.wmanager_response.end_log_operation_id,
              // 'last_case_point': resp.data.wmanager_response.last_case_point,
              // 'last_log_operation_id': resp.data.wmanager_response.last_log_operation_id,
              // 'last_root_layer_point': resp.data.wmanager_response.last_root_layer_point,
              // 'next': resp.data.wmanager_response.next,
              // 'status_code': resp.data.wmanager_response.status_code,
              // 'status_message': resp.data.wmanager_response.status_message,
              // 'total_root_layer_point': resp.data.wmanager_response.total_root_layer_point,
              // 'wdmr_count_list': resp.data.wmanager_response.wdmr_count_list
            };
            // console.log("wmanager: ", resp.data.wmanager_response.list)
            for (let i in resp.data.wmanager_response.list){
              // console.log("resp.data.wmanager_response.list[i]: ", resp.data.wmanager_response.list[i]);
              let operation_data_label = resp.data.wmanager_response.list[i].fields[0].data[0].data.general.operation_name.val;
              let operation_data = resp.data.wmanager_response.list[i].fields[0].data[0]
              this.d_taskCommentSeenList[operation_data['id']] = this.f_calculateCountOfSeenComments(operation_data);
              // this.d_taskList.push(operation_data);
              let operation_id = resp.data.wmanager_response.list[i].id;
              operation_id = operation_id.split("_")[1]
              let operation_wdmr = resp.data.wmanager_response.list[i].fields[0].data[0].id_tree[0].id;
              let operation_cd = resp.data.wmanager_response.list[i].fields[0].data[0].created_at;
              operation_cd = operation_cd.split("T")[0];
              let organization_id = operation_wdmr.slice(-2, -1)[0]
              organization_id = organization_id.split("_")[1]
              let organization_label = "";
              for(let i in this.d_organizationList){
                if (this.d_organizationList[i].w_id == organization_id){
                   organization_label = this.d_organizationList[i].label ;
                  break;
                }
              };
              // console.log("organization_id:", organization_id)
              let ticket = {
                "operation_data": operation_data,
                "organization_id": organization_id,
                "organization_label": organization_label,
                "operation_cd": operation_cd,
                "operation_id": operation_id,
                "label": operation_data_label,
                "status": "Passive"
              };
              this.d_passiveTicket.push(ticket);
            };
            console.log("d_passiveTicket: ", this.d_passiveTicket)

          }, resp => {
            let msg = resp.status_code + ' ' + resp.status_message;
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Hata mesajı' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            console.log(resp);
          });
      } else {
        alert('İşlem devam ediyor.');
      }
    },

    f_createSqlWhere: function () {
      // console.log(JSON.stringify(this.d_menuData));
      let where_sql = '';
      let layer_x = 'layer' + this.d_WmanagerLayerData['operation'].layer_id;
      let user_id_list_who_assigned = [this.user.id];
      if (user_id_list_who_assigned.length > 0) {
        if (where_sql === '') {
          where_sql = ' WHERE ';
        } else {
          where_sql += ' and ';
        }
        where_sql += layer_x + '[(general.log.__lp__.log_user = ' + user_id_list_who_assigned.join(',') + ') and (general.log.__lp__.log_status = assigned)] ';
      }
      // durum listesi atandı, görev tamamlandı..
      let status_list = ['assigned', 'started', 'pending', 'checking_task_completion', 'error', 'accepted', 'recommended'];
      if (status_list.length > 0) {
        if (where_sql === '') {
          where_sql = ' WHERE ';
        } else {
          where_sql += ' and ';
        }
        where_sql += layer_x + '[(general.status != ' + status_list.join(',') + ')] ';
      }
      return where_sql;
    },

    f_calculateCountOfSeenComments: function (task) {
      if (task.data && task.data.general && task.data.general.comment && task.data.general.comment.list) {
        let count = 0;
        for (let c in task.data.general.comment.list) {
          if (task.data.general.comment.list[c].who_saw && task.data.general.comment.list[c].who_saw.list) {
            for (let w in task.data.general.comment.list[c].who_saw.list) {
              if (task.data.general.comment.list[c].who_saw.list[w].user_saw.val && task.data.general.comment.list[c].who_saw.list[w].user_saw.val.value === this.user.id) {
                count += 1;
              }
            }
          }
        }
        return count.toString() + ' / ' + task.data.general.comment.list.length.toString();
      } else {
        return '0 / 0';
      }
    },
    f_goToThisTicket: function (wdmr307_id, wdmr17_id, status) {
      this.$router.push({ path: '/ticketdetails', query: { 'organization_id': wdmr307_id,  'operation_id': wdmr17_id, 'status': status} });

    },
  },
  watch: {}
};

</script>

<style>
.ticket_item: {
  height: 100%;
  cursor: pointer;
}
.line {
  width: 100%; /* Çizginin uzunluğunu belirleyin */
  height: 1px; /* Çizginin kalınlığını belirleyin */
  background-color: #000; /* Çizgi rengini belirleyin */
}

/*.ticket_item:hover {
  height: 100%;
  background: #85FFBD;
}
*/

</style>


